import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastrService: ToastrService, private router: Router) {}

  showMessage(statusCode: number, msg: any, action: string, redirectUrl?: string) {
    const toastrActions: any = {
      create: this.toastrService.success,
      update: this.toastrService.success,
      delete: this.toastrService.success,
      read: this.toastrService.success,
      info: this.toastrService.info,
    };

    if (statusCode >= 400) {
      this.handleError(msg);
    } else {
      const toastrAction = toastrActions[action];
      if (toastrAction) {
        toastrAction.call(this.toastrService, msg, 'HERMES - Sistema de Monitoramento');
      } else {
        this.toastrService.error(msg, 'HERMES - Sistema de Monitoramento');
      }

      if (redirectUrl) {
        this.router.navigateByUrl(redirectUrl);
      } else {
        this.router.navigateByUrl('/');
      }
    }
  }

  private handleError(msg: any) {
    this.toastrService.error(`${msg} - Ocorreu um erro ao fazer a chamada, por favor tente mais tarde!`, 'HERMES - Sistema de Monitoramento');
  }
}
