<div class="container-fluid">
  <div *ngIf="loginDisplay" class="row flex-nowrap">
    <div class="w-auto col-auto col-md-1 col-xl-1 px-0 ">
      <app-sidebar></app-sidebar>
    </div>
    <div id="content" class="col m-0 p-0" style="overflow: hidden">
      <app-content></app-content>
      <app-footer class="d-flex justify-content-center"></app-footer>
    </div>
  </div>
</div>
