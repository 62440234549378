import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { OperationDetails } from '../../../../models/insights.model'

import { InsightService } from '../insight.service'

import { NotificationService } from '../../../../shared/notification.service'

@Component({
	selector: 'app-detail-insight',
	templateUrl: './detail-insight.component.html',
	styleUrl: './detail-insight.component.scss',
})
export class DetailInsightComponent implements OnInit {
	title: 'DETAHES'
	dynamicURL: '/monitoring/insights'
	valia = '../../../../assets/images/marcadagua02-valia.svg'

	registerId: any
	registro: any = null
	registroDetails: Array<OperationDetails>

	messageInfo = { status: 0, message: '' }
	routerType: string

	private queryConfig: { dateRangeQuery?: string; defaultQuery: string; title: string; detailsRoute?: string }

	constructor(private insightService: InsightService, private router: Router, private notificationService: NotificationService) {}

	ngOnInit(): void {
		this.insightService.currentIds.subscribe((ids) => (this.registerId = ids))
		this.routerType = this.router.url.includes('failures') ? 'failures' : 'performance'
		this.queryConfig = this.insightService.getQueryConfig('operationDetails')
		this.readOperacionsDetails()
	}

	readOperacionsDetails(): void {
		if (Array.isArray(this.registerId) && this.registerId.length > 0) {
			try {
				let query = {}
				const idsString = this.registerId.map((id: any) => `"${id}"`).join(', ')

				query = this.queryConfig.defaultQuery!.replace('{idsString}', idsString)

				this.messageInfo = { status: 2, message: 'Consultando dados...' }

				this.insightService.executeQuery({ query: query }).subscribe({
					next: (result) => {
						this.formatedJsonInsights(result.tables)
						//this.registroDetails = this.insightService.processInsightsData(result.tables)
						this.messageInfo = { status: 3, message: 'Dados carregados com sucesso!' }
					},
					error: (error) => {
						this.notificationService.showMessage(error.status, error.statusText, 'read', this.dynamicURL)
						console.error('Erro ao consultar operações:', error)

						this.messageInfo = { status: 4, message: 'Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.' }
					},
				})
			} catch (error) {
				this.notificationService.showMessage(400, 'Erro inesperado ao processar a solicitação. Tente novamente mais tarde.', 'read', this.dynamicURL)
				console.error('Erro inesperado ao processar os IDs:', error)
				this.messageInfo = { status: 4, message: 'Erro inesperado ao processar a solicitação. Tente novamente mais tarde.' }
			}
		} else {
			this.messageInfo = { status: 4, message: 'Nenhum ID registrado para consulta.' }
		}
	}

	formatedJsonInsights(data: any) {
		this.registroDetails = data[0].rows.map((row: any) => {
			let obj: { [key: string]: any } = {}
			data[0].columns.forEach((column: any, index: any) => {
				obj[column.name] = row[index]
			})
			return obj
		})
	}

	handleClick(registro: any): void {
		this.registro = registro
		this.insightService.setData(registro)
	}

	getFormattedDuration(registroDuration: number): string {
		const durationMs = registroDuration
		if (durationMs >= 60000) {
			// Maior ou igual a 1 minuto
			const durationMins = durationMs / 60000
			return `${durationMins.toFixed(2)} mins`
		} else if (durationMs >= 1000) {
			// Maior ou igual a 1 segundo
			const durationSecs = durationMs / 1000
			return `${durationSecs.toFixed(2)} secs`
		} else {
			return `${durationMs.toFixed(2)} ms`
		}
	}
}
