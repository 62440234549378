import { Component } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { ProfileService } from './../../shared/profile.service'

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
	imgPath = '../../../assets/images/icon-valia.svg'
	home = '../../../assets/icons/home-azul.svg'
	monitoring = '../../../assets/icons/dashboard-azul.svg'
	people = '../../../assets/icons/person-circle-azul.svg'
	pta = '../../../assets/icons/PTA-file.svg'
	money = '../../../assets/icons/money-azul.svg'
  alert = '../../../assets/icons/alerts.svg'

	nome: any = ''
	nomeReduzido: string = ''

	loginDisplay = false

	userGroup: any = []
	groups: any = []

	profile_hermes_tecnico = false
	profile_hermes_cadastro = false
  profile_hermes_financeiro = false
	profile_hermes_pta = false

	constructor(private msalService: MsalService, private profileService: ProfileService) {
		this.profile_hermes_cadastro = this.profileService.checkedProfileHermesCadastro()
		this.profile_hermes_tecnico = this.profileService.checkedProfileHermesTecnico()
		this.profile_hermes_pta = this.profileService.checkedProfileHermesPTA()
    this.profile_hermes_financeiro = this.profileService.checkedProfileHermesFinanceiro()
	}

	ngOnInit(): void {
		this.getProfile()
		this.loginDisplay = this.msalService.instance.getAllAccounts() ? true : false
	}

	reduzNome(name: string) {
		var quebraNome = name.split(' ')
		this.nomeReduzido = `${quebraNome[0].substring(0, 1)}${quebraNome[1].substring(0, 1)}`
	}

	getProfile() {
		const profile = this.msalService.instance.getAllAccounts()
		this.nome = profile[0].name
		this.reduzNome(this.nome)
	}

	onLogout() {
		this.msalService.logout()
		localStorage.removeItem('token')
	}
}
