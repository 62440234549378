import { Component } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ToastrService } from 'ngx-toastr'

import { environment } from '../../../environments/environment'

import { Router } from '@angular/router'
import { LoginService } from '../../shared/login.service'



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  valia = '../../../../assets/images/marcadagua02-valia.svg'
  hermes = '../../../../assets/images/hermes-valia.svg'
  mensagem: string = "Sistema de Monitoramento das Integrações"
	textoMensagem!: SafeHtml
	loginDisplay = false

	title: string = 'HERMES'

	constructor(
		private sanitized: DomSanitizer,
		private loginService: LoginService,
		private toastrService: ToastrService,
		private router: Router
	) {

	}

	ngOnInit(): void {
		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated
	}

	getInnerHTMLValue(value: string) {
		this.textoMensagem = this.sanitized.bypassSecurityTrustHtml(value)
	}



	showAlertInfo(msg: any) {
		this.toastrService.error(`${msg} - Ocorreu um erro ao fazer a chamada, por favor tente mais tarde!`, 'PTA - Portal de Transferência de Arquivos', {
			timeOut: 2000,
			closeButton: true,
		})
		this.router.navigateByUrl('/home')
	}
}
