<div class="card stretch-card">
	<div class="card-header card-header-customer pb-2 d-flex justify-content-between align-items-center">
		<!-- Search Input -->
		<div class="input-group search w-25 mb-3">
			<input class="form-control" type="text" placeholder="Pesquisar na página" [(ngModel)]="searchTermValue" name="searchTerm" style="height: 2.5rem"/>
		</div>
		<form class="row row-cols-sm-auto justify-content-center align-items-center mb-3">
			<div class="col-12">
				<div class="input-group">
					<input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="searchDate" ngbDatepicker #d="ngbDatepicker" />
					<button class="btn btn-input-customer" id="button-addon2" (click)="d.toggle()" type="button">
						<em i-bs name="calendar-range"></em>
					</button>
				</div>
			</div>

			<div class="col-12 d-flex align-items-center">
				<button type="button" class="btn btn-log-customer btn-customer-info" (click)="loadData()">
					<em i-bs name="search"></em>
				</button>
				<button type="button" class="btn btn-log-customer btn-customer-danger" (click)="clearFilters()">
					<em i-bs name="x" width="23" height="23"></em>
				</button>
			</div>
		</form>
	</div>

	<div *ngIf="notification.status === 2" class="d-flex justify-content-center align-items-center p-5" style="height: 65vh">
		<div class="spinner-border" role="status"></div>
	</div>

	<table *ngIf="notification.status === 3" class="table table-sm table-hover align-middle">
		<caption style="display: none">
			Tabela mostra informações referente ao dataLake Signin
		</caption>
		<thead class="table-light">
			<tr>
				<th scope="col" class="text-center align-middle">id</th>
				<th scope="col" class="text-center align-middle">Data</th>
				<th scope="col" class="text-center align-middle">Nome</th>
				<th scope="col" class="text-center align-middle">Email</th>
				<th scope="col" class="text-center align-middle" style="width: 6rem">Detalhes</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let file of paginatedFileSignIn">
				<td class="align-middle">{{ file.id }}</td>
				<td class="text-center align-middle">{{ file.createdDateTime }}</td>
				<td class="text-center align-middle">{{ file.userDisplayName }}</td>
				<td class="text-center align-middle">{{ file.userPrincipalName }}</td>
				<td class="text-center align-middle" style="width: 6rem">
					<button type="button" class="btn btn-customer-info" (click)="openDetailModal(file, modalDetail)">
						<em i-bs name="justify" width="20" height="20"></em>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="card-footer-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<ngb-pagination
			class="pagination-customer"
			[collectionSize]="collectionSize"
			[(page)]="page"
			[pageSize]="pageSize"
			(pageChange)="paginateFileSignIn()"
		></ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="paginateFileSignIn()">
			<option [ngValue]="500">500 items por página</option>
			<option [ngValue]="1000">1000 items por página</option>
			<option [ngValue]="2000">2000 items por página</option>
		</select>
	</div>
</div>

<ng-template #modalDetail let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<app-data-lake-detail-card [details]="selectedFileDetails"></app-data-lake-detail-card>
		<app-data-lake-status-card [status]="selectedFileDetails.status"></app-data-lake-status-card>
		<app-data-lake-device-card [deviceDetail]="selectedFileDetails.deviceDetail"></app-data-lake-device-card>
		<app-data-lake-location-card [location]="selectedFileDetails.location"></app-data-lake-location-card>
	</div>
</ng-template>
