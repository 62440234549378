import { Component } from '@angular/core'

@Component({
	selector: 'app-pta',
	templateUrl: './pta.component.html',
	styleUrl: './pta.component.scss',
})
export class PtaComponent {
  title = "PTA"
	constructor() {}
}
