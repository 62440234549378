import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, of, tap } from 'rxjs'

interface CacheEntry {
	data: any
	timestamp: number
}

@Injectable({
	providedIn: 'root',
})
export class DataLakeService {
	constructor(private http: HttpClient) {}

	getData(container: string, parameters: string[], continuationToken?: string, pageSize: number = 500, operationName?: string): Observable<any> {
    const queryParameters = parameters.map((path) => `parameters=${encodeURIComponent(path)}`).join('&')
    + (operationName ? `&operationName=${encodeURIComponent(operationName)}` : '');

		const headers = {
			'X-Continuation-Token': continuationToken || '',
			'X-Page-Size': pageSize.toString(),
      		'X-File-System-Name': container.toString()
		}

    console.log(headers, 'headers')
    console.log(queryParameters, 'queryParameters')

		return this.http
			.get<any>(`insights/folder?${queryParameters}`, { headers, observe: 'body' })
			.pipe(
				tap((data) => {
					console.log(`Dados recebidos para ${container} com os parâmetros: ${queryParameters}`)
				}),
				catchError(this.handleError<any>('List Data', null))
			)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
