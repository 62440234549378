import { Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl } from '@angular/forms'

import { MsalService } from '@azure/msal-angular'

import { ModalDismissReasons, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker'

import { defineLocale } from 'ngx-bootstrap/chronos'
import { ptBrLocale } from 'ngx-bootstrap/locale'

import { Company } from '../../../models/company.model'
import { Process } from '../../../models/process.model'
import { Group } from '../../../models/groups.model'

import {
	RecordActivitiesCheckFiles,
	RecordActivitiesCriticize,
	RecordActivitiesProcessed,
	RecordActivitiesUpload,
} from '../../../models/record-activities.model'

import { LoginService } from '../../../shared/login.service'
import { ProfileService } from '../../../shared/profile.service'
import { NotificationService } from '../../../shared/notification.service'

import { PtaService } from './../pta.service'
import { RegistroCosmosService } from './../../../shared/registro-cosmos.service'
import { environment } from '../../../../environments/environment'

defineLocale('pt-br', ptBrLocale)

const cosmos_table = environment.COSMOS_TABLE
@Component({
	selector: 'app-pta-in',
	templateUrl: './pta-in.component.html',
	styleUrl: './pta-in.component.scss',
})
export class PtaInComponent implements OnInit {
	title = 'PTA-IN'
	dynamicUrl = '/pta/pta-in'

	recordCriticize: Array<RecordActivitiesCriticize> = []
	recordPTAxFase: Array<RecordActivitiesProcessed> = []
	recordCheckFiles: Array<RecordActivitiesCheckFiles> = []
	recordPTAxEmprestimo: Array<RecordActivitiesCheckFiles> = []
	recordEmprestimoxPTA: Array<RecordActivitiesCheckFiles> = []
	record: RecordActivitiesUpload
	records: Array<RecordActivitiesUpload>
	processes: Array<Process>
	companies: Array<Company>
	user: any

	private groupsCache: Group[] = []

	recordPTAxFaseCount: number = 0
	recordCriticizeCount: number = 0
	recordCheckFilesCount: number = 0
	recordPTAxEmprestimoCount: number = 0
	recordEmprestimoxPTACount: number = 0

	requiredMessage: string

	dataInicio: string = 'Data Início'
	dataFim: string = 'Data Fim'

	typeFiles: string | undefined
	codeCompanies: string | undefined

	filesFilter: string[] = []
	companiesFilter: string[] = []

	selectedTypeFiles = 'Arquivos'
	selectedCompanies = 'Empresas'

	notification = { status: 0, message: '' }
	notificationModal = { status: 0, message: '' }

	// Calendar
	selectedDate: Date[] | null = null
	bsConfig: Partial<BsDatepickerConfig>

	//Modal
	modalRef: NgbModalRef
	closeResult: string = ''

	// paginação
	page = 1
	pageSize = 20
	collectionSize: number
	pagefiltered: Array<RecordActivitiesUpload> = []

	//Collapse
	isCollapsed = false

	//Perfis Liberados
	loginDisplay = false

	// Search
	searchTerm: string = ''
	filtered: Array<RecordActivitiesUpload> = []
	searchFilter = new FormControl('')

	profile_hermes_tecnico = false
	profile_pta_cadastro = false
	profile_pta_emprestimo = false
	profile_pta_arrecadacao = false
	profile_pta_consignataria = false

	constructor(
		private ptaService: PtaService,
		private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter,
		public loginService: LoginService,
		private profileService: ProfileService,
		private msalService: MsalService,
		private modalService: NgbModal,
		private notificationService: NotificationService,
		private registroCosmosService: RegistroCosmosService,
		private localeService: BsLocaleService
	) {
		this.localeService.use('pt-br')
		this.bsConfig = {
			rangeInputFormat: 'YYYY-MM-DD',
			dateInputFormat: 'YYYY-MM-DD',
			containerClass: 'theme-dark-blue',
			displayMonths: 2,
			showPreviousMonth: true,
			showTodayButton: true,
			maxDate: new Date(),
		}

		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated

		this.profile_hermes_tecnico = this.profileService.checkedProfileHermesTecnico()
		this.profile_pta_cadastro = this.profileService.checkedProfilePtaCadastro()
		this.profile_pta_emprestimo = this.profileService.checkedProfilePtaEmprestimo()
		this.profile_pta_arrecadacao = this.profileService.checkedProfilePtaArrecadacao()
		this.profile_pta_consignataria = this.profileService.checkedProfilePtaConsignataria()
	}

	ngOnInit() {
		this.readGroups()
		this.readCompanies()
		this.readProcesses()
		this.readUsersByEmail()
	}

	adicionaZero(numero: number) {
		if (numero <= 9) return '0' + numero
		else return numero
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.filtered = this.records
		this.searchTerm = value
		this.filtered = this.searchTerm ? this.onFilter(this.searchTerm) : this.records
		this.collectionSize = this.filtered.length
		this.refreshPage()
	}

	onFilter(text: string): Array<RecordActivitiesUpload> {
		const term = text.toLowerCase()
		return this.records.filter((file) => {
			const nomeArquivo = file?.nomeArquivo?.toLowerCase() || ''
			const usuario = file?.usuario?.toLowerCase() || ''
			const nomeGrupo = file?.nomeGrupo?.toLowerCase() || ''
			return nomeArquivo.includes(term) || usuario.includes(term) || nomeGrupo.includes(term)
		})
	}

	refreshPage() {
		this.pagefiltered = this.searchTerm
			? this.filtered.map((record, i) => ({ index: i + 1, ...record })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
			: this.records.map((record, i) => ({ index: i + 1, ...record })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	convertToTimestamp(dateString: string) {
		const date = new Date(dateString)
		return date.getTime() / 1000
	}

	readProcesses() {
		this.ptaService.getProcessos().subscribe((res) => {
			if (res.status >= 400) {
				this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
			} else {
				this.processes = res.processos
				this.selectProcessesByProfiles()
			}
		})
	}

	readCompanies() {
		this.ptaService.getEmpresas().subscribe((res) => {
			if (res.status >= 400) {
				this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
			} else {
				this.companies = res.empresas.filter((emp: Company) => emp.status === 'ativo')
			}
		})
	}

	async readGroups(): Promise<void> {
		const groupsFromCache = localStorage.getItem('groupsCache-PTA')

		if (groupsFromCache) {
			// Se estiverem, faça o parse do JSON e atribua à variável this.groupsCache
			this.groupsCache = JSON.parse(groupsFromCache)
			console.log('Usando grupos do cache (localStorage)')
			return // Retorne sem fazer a requisição novamente
		}

		return new Promise((resolve, reject) => {
			this.ptaService.getGrupos().subscribe({
				next: (res) => {
					if (res.status >= 400) {
						this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
						reject(new Error('Erro ao carregar grupos'))
					} else {
						this.groupsCache = res.grupos
						localStorage.setItem('groupsCache-PTA', JSON.stringify(this.groupsCache))
						resolve()
					}
				},
				error: (err) => {
					this.notificationService.showMessage(500, 'Erro ao carregar grupos', 'read', this.dynamicUrl)
					reject(err)
				},
			})
		})
	}

	readUsersByEmail() {
		let user: any
		const profile = this.msalService.instance.getAllAccounts()
		if (profile) {
			user = profile[0].username
		}
		this.ptaService.getUsuarioPorEmail(user).subscribe((res) => {
			if (res.status >= 400) {
				this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
			} else {
				this.user = res.usuario
			}
		})
	}

	selectProcessesByProfiles() {
		let typeFiles: Array<Process> = []

		if (this.profile_pta_emprestimo) {
			typeFiles = typeFiles.concat(this.processes.filter((p) => p.responsavel_valia == 'Emprestimos' || p.responsavel_valia == 'Empréstimos'))
		}
		if (this.profile_pta_arrecadacao) {
			typeFiles = typeFiles.concat(this.processes.filter((p) => p.responsavel_valia == 'Arrecadação' || p.responsavel_valia == 'Arrecadacao'))
		}
		if (this.profile_pta_cadastro) {
			typeFiles = typeFiles.concat(this.processes.filter((p) => p.responsavel_valia == 'Cadastro'))
		}
		if (this.profile_pta_consignataria) {
			typeFiles = typeFiles.concat(this.processes.filter((p) => p.responsavel_valia == 'Benefícios' || p.responsavel_valia == 'Beneficios'))
		}
		if (this.profile_hermes_tecnico) {
			typeFiles = typeFiles.concat(this.processes)
		}
		typeFiles = [...new Set(typeFiles)]
		this.completeSelectTypeFiles(typeFiles)
		this.readRecordActivitiesUpload(typeFiles)
	}

	readRecordActivitiesUpload(typeFiles: any) {
		this.requiredMessage = ''

		const hasDate = this.selectedDate !== null
		var date = new Date()

		var dayInitial = String(date.getDate())
		var monthInitial = String(date.getMonth() + 1)
		var yearInitial = date.getFullYear()

		let formattedFromDate = 0
		let formattedToDate = 0

		if (hasDate) {
			const dateStart = new Date(this.selectedDate![0])
			const dateEnd = new Date(this.selectedDate![1])

			formattedFromDate = this.convertToTimestamp(
				`${dateStart.getFullYear()}-${this.adicionaZero(dateStart.getMonth() + 1)}-${this.adicionaZero(dateStart.getDate())} 00:00:00`
			)
			formattedToDate = this.convertToTimestamp(
				`${dateEnd.getFullYear()}-${this.adicionaZero(dateEnd.getMonth() + 1)}-${this.adicionaZero(dateEnd.getDate())} 23:59:59`
			)
		} else {
			formattedFromDate = this.convertToTimestamp(`${yearInitial}/${monthInitial}/${dayInitial} 00:00:00`)
			formattedToDate = this.convertToTimestamp(`${yearInitial}/${monthInitial}/${dayInitial} 23:59:59`)
		}

		const query = {
			query: `SELECT * FROM c WHERE (c.tipo = 'upload' AND (NOT IS_DEFINED(c.nomeArquivoEnviado) OR NOT CONTAINS(c.nomeArquivoEnviado, 'calculoretroativo')) AND (c._ts >= ${formattedFromDate} AND c._ts <= ${formattedToDate})) OR (c.tipo = 'upload-ptaout' AND c.nomeProcesso = 'cargaentidadeexterna' AND (c._ts >= ${formattedFromDate} AND c._ts <= ${formattedToDate})) ORDER BY c._ts DESC`,
		}

		this.notification.status = 2
		this.registroCosmosService.readRegisterCosmosSDK('pta', 'registro-atividades', query).subscribe((res) => {
			switch (true) {
				case res.status >= 400:
					this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
					break
				case res._count == 0:
					this.notification = { status: 1, message: 'Não existem arquivos processados para hoje!' }
					break
				default:
					this.notification.status = 3
					const response = res.Documents
					const records = Array.isArray(response) ? response : [response]

					this.readGroups().then(() => {
						records.forEach((record) => {
							if (record.nomeProcesso === 'emprestimos' || record.nomeProcesso === 'cargaentidadeexterna') {
								const codigo = record.nomeArquivoEnviado?.split('_')[0]
								const grupo = this.groupsCache.filter((group: Group) => group.empresas.find((item) => item.codigo === codigo))
								record.nomeGrupo = grupo.map((item) => item.nome)[0]
							}
						})
						this.selectFilesByProfiles(records, typeFiles)
					})
					break
			}
		})
	}

	selectFilesByProfiles(response: any, typeFiles: any) {
		let files: Array<RecordActivitiesUpload> = []

		if (this.profile_hermes_tecnico) {
			files = files.concat(response)
		} else {
			const typeFileNames = typeFiles.map((type: any) => type.nome)

			files = files.concat(
				response.filter((file: any) => {
					return typeFileNames.includes(file.nomeProcesso)
				})
			)
		}
		this.records = files
		this.filtered = files
		this.collectionSize = this.records.length
		this.refreshPage()
	}

	readRecordActivitiesByCheckFiles(recordUpload: RecordActivitiesUpload) {
    console.log(recordUpload, 'entrada verificacao arquivos')
		this.notificationModal.status = 1
		const query = {
			query: `SELECT * FROM c WHERE c.tipo = 'VerificacaoArquivos' AND CONTAINS(c.nomeArquivo, '${recordUpload.nomeArquivo}') `,
		}

		this.registroCosmosService.readRegisterCosmos('pta', 'registro-atividades', query).subscribe((res) => {
      console.log(res, 'verificacao arquivos')
			this.notificationModal.status = 2
			this.recordCheckFilesCount = 0
			switch (true) {
				case res.status >= 400:
					this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
					break
				default:
					const response = res.Documents
					this.recordCheckFilesCount = res._count
					this.recordCheckFiles = Array.isArray(response) ? response : [response]
					this.notificationModal.status = 3
					this.readRecordActivitiesByCriticize(this.recordCheckFiles)
					this.readRecordActivitiesByPTAxFASe(this.recordCheckFiles)
					break
			}
		})
	}

	readRecordActivitiesByCriticize(recordUpload: any) {
		this.notificationModal.status = 1
		const urlParts = recordUpload[0].nomeArquivoTratado.split('/')
		const fileNameWithParams = urlParts[urlParts.length - 1]
		const fileName = fileNameWithParams.split('?')[0]
		const query = {
			query: `SELECT * FROM c WHERE c.tipo = 'CriticasArquivos' AND CONTAINS(c.nomeArquivo, '${fileName}') `,
		}

		this.registroCosmosService.readRegisterCosmos('pta', 'registro-atividades', query).subscribe((res) => {
			console.log(res, 'response critica')
			this.notificationModal.status = 2
			this.recordCriticizeCount = 0
			switch (true) {
				case res.status >= 400:
					this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
					break
				default:
					const response = res.Documents
					this.recordCriticizeCount = res._count
					this.recordCriticize = Array.isArray(response) ? response : [response]
					this.notificationModal.status = 3
					break
			}
		})
	}

	readRecordActivitiesByPTAxFASe(recordUpload: any) {
		let fileName = ''
    console.log(recordUpload, 'pta-fase')
		this.notificationModal.status = 1
		if (recordUpload.nomeProcesso === 'contribuicoes') {
      console.log(recordUpload, 'ptaxfase')
			const urlParts = recordUpload.nomeArquivo.split('/')
			const fileNameWithParams = urlParts[urlParts.length - 1]
			fileName = fileNameWithParams.split('?')[0]
		} else {
			const urlParts = recordUpload[0].nomeArquivoEnviadoTratado.split('/')
			const fileNameWithParams = urlParts[urlParts.length - 1]
			fileName = fileNameWithParams.split('?')[0]
      console.log(fileName, 'nome arquivo')
		}

		const query = {
			query: `SELECT * FROM c WHERE c.tipo = 'PTAXFASe' AND CONTAINS(c.nomeArquivo, '${fileName}') `,
		}

    console.log(query, 'query')

		this.registroCosmosService.readRegisterCosmos('pta', 'registro-atividades', query).subscribe((res) => {
			console.log(res, 'ptafase')
			this.notificationModal.status = 2
			switch (true) {
				case res.status >= 400:
					this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
					break
				default:
					const response = res.Documents
					this.recordPTAxFaseCount = res._count
					this.recordPTAxFase = Array.isArray(response) ? response : [response]
					this.notificationModal.status = 3
					break
			}
		})
	}

	readRecordActivitiesByPTAxEmprestimo(recordUpload: RecordActivitiesUpload) {
		this.notificationModal.status = 1
		const urlParts = recordUpload.nomeArquivo.split('/')
		const fileNameWithParams = urlParts[urlParts.length - 1]
		const fileName = fileNameWithParams.split('?')[0]

		const query = {
			query: `SELECT * FROM c WHERE c.tipo = 'PTAxEmprestimo' AND CONTAINS(c.nomeArquivo, '${fileName}')`,
		}

		this.registroCosmosService.readRegisterCosmos('pta', 'registro-atividades', query).subscribe((res) => {
      console.log(res, 'PTAxEmprestimo')
			this.notificationModal.status = 2
			switch (true) {
				case res.status >= 400:
					this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
					break
				default:
					const response = res.Documents
					this.recordPTAxEmprestimoCount = res._count
					this.recordPTAxEmprestimo = Array.isArray(response) ? response : [response]
					this.notificationModal.status = 3
					break
			}
		})
	}

	readRecordActivitiesByEmprestimoxPTA(recordUpload: RecordActivitiesUpload) {
		this.notificationModal.status = 1
		const urlParts = recordUpload.nomeArquivoEnviado.split('/')
		const fileNameWithParams = urlParts[urlParts.length - 1]
		const fileName = fileNameWithParams.split('?')[0]

		const query = {
			query: `SELECT * FROM c WHERE c.tipo = 'EmprestimoxPTA' AND CONTAINS(c.nomeArquivo, '${fileName}')`,
		}

		this.registroCosmosService.readRegisterCosmos('pta', 'registro-atividades', query).subscribe((res) => {
      console.log(res, 'EmprestimoxPTA')
			this.notificationModal.status = 2
			switch (true) {
				case res.status >= 400:
					this.notificationService.showMessage(res.status, res.statusText, 'read', this.dynamicUrl)
					break
				default:
					const response = res.Documents
					this.recordEmprestimoxPTACount = res._count
					this.recordEmprestimoxPTA = Array.isArray(response) ? response : [response]
					this.notificationModal.status = 3
					break
			}
		})
	}

	nameFileFormatedTable(nomeFile: string) {
		var splitExt = nomeFile.split('.')
		var splitName = splitExt[0].split('_')
		var splitNamePOP = splitName.pop()
		var qt = splitName.length

		if (qt < 3) {
			return nomeFile
		}

		var codigo = splitName[0]
		var anoMes = splitName[qt - 1]
		var extensao = splitExt[1]

		var processos = splitName.slice(1, qt - 1)

		return `${codigo}_${processos.join('_')}_${anoMes}.${extensao}`
	}

	completeSelectTypeFiles(typeFiles: any) {
		this.filesFilter = []
		for (var i = 0; typeFiles.length > i; i++) {
			this.filesFilter.push(`${typeFiles[i].nome.toLowerCase()}`)
		}
	}

	onDateSelection(event: any) {
		this.selectedDate = event
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input)
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue
	}

	requiredVerification() {
		if (!this.selectedDate) {
			this.requiredMessage = 'Campo obrigatório!'
		} else {
			this.ngOnInit()
		}
	}

	clearFilters(input: HTMLInputElement): void {
		input.value = ''
		this.requiredMessage = ''
		this.onDateSelection(null)
		this.cleanAndReload()
	}

	cleanAndReload() {
		this.searchTermValue = ''
		this.collectionSize = 0
		this.notification = { status: 0, message: '' }
		this.ngOnInit()
	}

	detailModal(record: RecordActivitiesUpload, form: TemplateRef<any>) {
    console.log(record, 'dados modal')
		if (record.nomeProcesso.includes('emprestimos')) {
			console.log('entrou')
			this.readRecordActivitiesByPTAxEmprestimo(record)
		} else if (record.nomeProcesso.includes('cargaentidadeexterna')) {
			this.readRecordActivitiesByEmprestimoxPTA(record)
		} else if (record.nomeProcesso.includes('contribuicoes')) {
      console.log('entrou aqui modal')
			this.readRecordActivitiesByPTAxFASe(record)
		} else {
			this.readRecordActivitiesByCheckFiles(record)
		}

		this.openModal(form)
	}

	openModal(form: TemplateRef<any>) {
		this.modalRef = this.modalService.open(form, { size: 'xl', scrollable: true, centered: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	onDismiss() {
		this.modalRef.dismiss()
		this.recordCriticize = []
		this.recordPTAxFase = []
		this.recordCheckFiles = []
		this.recordPTAxEmprestimo = []
    this.recordEmprestimoxPTA = []

    this.recordCheckFilesCount = 0
    this.recordCheckFilesCount = 0
    this.recordPTAxFaseCount = 0
    this.recordPTAxEmprestimoCount = 0
    this.recordEmprestimoxPTACount = 0
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}
}
