import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core'

import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { CustomDimensions, CustomMeasurements, OperationDetails } from '../../../../../models/insights.model'

import { InsightService } from '../../insight.service'

@Component({
	selector: 'app-operation-detail',
	templateUrl: './operation-detail.component.html',
	styleUrl: './operation-detail.component.scss',
})
export class OperationDetailComponent implements OnInit {
	@ViewChild('codeBlockRequest', { static: false }) codeBlockRequest: ElementRef | undefined
	@ViewChild('codeBlockResponse', { static: false }) codeBlockResponse: ElementRef | undefined

	operationDetails: OperationDetails
	customerDimensions: CustomDimensions | null = null
	customMeasurements: CustomMeasurements | null = null
	customerDimensionsRequestBody: any = null
	customerDimensionsResponseBoby: any = null

	requestXML: any
	requestJson: any
	responseXML: any
	responseJson: any
	contentType: string

	// modal
	size: string = ''
	modalRef!: NgbModalRef
	closeResult: string = ''
	modalDetails: any

	constructor(private insightService: InsightService, private modalService: NgbModal) {}

	async ngOnInit() {
		await this.insightService.data$.subscribe((data) => {
			this.operationDetails = data
			this.customerDimensions = this.parseAndRenameKeys(this.operationDetails.customDimensions)
			this.customMeasurements = this.parseAndRenameKeys(this.operationDetails.customMeasurements)
			this.customerDimensionsRequestBody =
				this.isJsonOrXml(this.customerDimensions?.RequestBody) === 'json'
					? JSON.parse(this.customerDimensions?.RequestBody)
					: this.customerDimensions?.RequestBody
			this.customerDimensionsResponseBoby =
				this.isJsonOrXml(this.customerDimensions?.ResponseBody) === 'json'
					? JSON.parse(this.customerDimensions?.ResponseBody)
					: this.customerDimensions?.ResponseBody
		})
	}

	parseAndRenameKeys(jsonString: any): any {
		const parsed = JSON.parse(jsonString)
		const formatted: any = {}

		Object.keys(parsed).forEach((key: string) => {
			const formattedKey = key.replace(/(?:^\w|[A-Z]|\b\w|\s+|-|\(|\))/g, (match, index) => (match.trim() ? match.toUpperCase() : '')).replace(/[\s-()]+/g, '')

			formatted[formattedKey] = parsed[key]
		})

		return formatted
	}

	isJsonOrXml(input: string): 'json' | 'xml' | 'unknown' {
		// Verificação para input vazio
		if (!input || input.trim() === '') {
			this.contentType = 'unknown'
			return 'unknown'
		}

		// Removendo espaços em branco no início e no fim da string
		const trimmedInput = input.trim()

		// Verificação simples para JSON
		if (trimmedInput.startsWith('{') || trimmedInput.startsWith('[')) {
			try {
				// Tentativa de fazer parse para verificar se é um JSON válido
				JSON.parse(trimmedInput)
				this.contentType = 'json'
				return 'json'
			} catch (e) {
				// Se ocorrer erro no JSON.parse, não é um JSON válido
				this.contentType = 'unknown'
				return 'unknown'
			}
		}

		// Verificação simples para XML
		if (trimmedInput.startsWith('<') && trimmedInput.endsWith('>')) {
			// Verificar se o conteúdo é um XML bem formatado
			try {
				const parser = new DOMParser()
				const xmlDoc = parser.parseFromString(trimmedInput, 'application/xml')

				// Se houver erros de parsing, o documento terá erros
				const parseError = xmlDoc.getElementsByTagName('parsererror')
				if (parseError.length === 0) {
					this.contentType = 'xml'
					return 'xml'
				} else {
					this.contentType = 'unknown'
					return 'unknown'
				}
			} catch (e) {
				this.contentType = 'unknown'
				return 'unknown'
			}
		}

		// Se não for nem JSON nem XML
		this.contentType = 'unknown'
		return 'unknown'
	}

	async copyText(registro: any) {
		try {
			const text = typeof registro === 'string' ? registro : JSON.stringify(registro, null, 2)

			await navigator.clipboard.writeText(text)
		} catch (error) {
			console.error('Failed to copy:', error)
		}
	}

	modalContentDetails(content: any, form: TemplateRef<any>): void {
		this.contentType = ''

		const stringContent = typeof content === 'string' ? content.trim() : JSON.stringify(content)

		this.contentType = this.isJsonOrXml(stringContent)

		this.modalDetails = content

		this.openModal(form, (this.size = 'xl'))
	}

	openModal(form: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.open(form, { size, scrollable: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}
}
