import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormControl } from '@angular/forms'
import { NgbDateParserFormatter, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker'

import { defineLocale } from 'ngx-bootstrap/chronos'
import { ptBrLocale } from 'ngx-bootstrap/locale'

import { Insights } from '../../../../models/insights.model'
import { Cadastro } from '../../../../models/cadastro.model'

import { InsightService } from '../insight.service'

import { NotificationService } from './../../../../shared/notification.service'

defineLocale('pt-br', ptBrLocale)

@Component({
	selector: 'app-generic-insight',
	templateUrl: './generic-insight.component.html',
	styleUrls: ['./generic-insight.component.scss'],
})
export class GenericInsightComponent implements OnInit {
	title: string = ''
	insightsData: Insights[] = []
	detailsData: Cadastro[] = []
	insightsCount: number = 0
	notification = { status: 0, message: '' }
	queryType: any

	// Search
	searchTerm: string = ''
	filteredInsights: Insights[] = []
	searchFilter = new FormControl('')
	requiredMessage: string = ''

	// Calendar
	selectedDate: Date[] | null = null
	bsConfig: Partial<BsDatepickerConfig>

	// Pagination
	page = 1
	pageSize = 100
	collectionSize: number = 0
	paginatedInsights: Insights[] = []

	// Modal
	modalRef: NgbModalRef | null = null
	closeResult: string = ''

	ranges: any = [
    {
      value: [new Date(new Date().setHours(new Date().getHours() - 1)), new Date()],
      label: 'Last 1 Hours',
    },
    {
      value: [new Date(new Date().setHours(new Date().getHours() - 4)), new Date()],
      label: 'Last 4 Hours',
    },
    {
      value: [new Date(new Date().setHours(new Date().getHours() - 6)), new Date()],
      label: 'Last 6 Hours',
    },
    {
      value: [new Date(new Date().setHours(new Date().getHours() - 12)), new Date()],
      label: 'Last 12 Hours',
    },
    {
			value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date()],
			label: 'Last 24 Hours',
		},
    {
			value: [new Date(new Date().setDate(new Date().getDate() - 3)), new Date()],
			label: 'Last 3 Days',
		},
		{
			value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
			label: 'Last 7 Days',
		},
		{
			value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
			label: 'Last 30 Days',
		},
	]

	private queryConfig: { dateRangeQuery?: string; defaultQuery: string; title: string; detailsRoute?: string }

	constructor(
		public formatter: NgbDateParserFormatter,
		private router: Router,
		private insightsService: InsightService,
		private notificationService: NotificationService,
		private localeService: BsLocaleService
	) {
		this.localeService.use('pt-br')
		this.bsConfig = {
			rangeInputFormat: 'YYYY-MM-DD HH:mm:ss',
			dateInputFormat: 'YYYY-MM-DD HH:mm:ss',
			ranges: this.ranges,
			containerClass: 'theme-dark-blue',
			displayMonths: 2,
			showPreviousMonth: true,
      showTodayButton: true,
      maxDate: new Date(),
		}
	}

	ngOnInit(): void {
		this.queryType = this.router.url.includes('failures') ? 'failures' : 'performance'
		this.queryConfig = this.insightsService.getQueryConfig(this.queryType)
		this.loadInsightsData()
	}

	adicionaZero(numero: number) {
		if (numero <= 9) return '0' + numero
		else return numero
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.searchTerm = value
		this.filteredInsights = this.searchTerm ? this.filterInsights(this.searchTerm) : this.insightsData
		this.collectionSize = this.filteredInsights.length
		this.paginateInsights()
	}

	filterInsights(term: string): Insights[] {
		const lowerCaseTerm = term.toLowerCase()
		return this.insightsData.filter((insight) => insight.operation_Name.toLowerCase().includes(lowerCaseTerm))
	}

	paginateInsights(): void {
		this.paginatedInsights = this.filteredInsights
			.map((arquivo, i) => ({ index: i + 1, ...arquivo }))
			.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	loadInsightsData(): void {
		const hasDate = this.selectedDate !== null

		let formattedFromDate = ''
		let formattedToDate = ''

		if (hasDate) {
			const dateStart = new Date(this.selectedDate![0])
			const dateEnd = new Date(this.selectedDate![1])

			formattedFromDate = `${dateStart.getFullYear()}-${this.adicionaZero(dateStart.getMonth() + 1)}-${this.adicionaZero(
				dateStart.getDate()
			)}T${this.adicionaZero(dateStart.getHours())}:${this.adicionaZero(dateStart.getMinutes())}:${this.adicionaZero(dateStart.getSeconds())}Z`
			formattedToDate = `${dateEnd.getFullYear()}-${this.adicionaZero(dateEnd.getMonth() + 1)}-${this.adicionaZero(dateEnd.getDate())}T${this.adicionaZero(
				dateEnd.getHours()
			)}:${this.adicionaZero(dateEnd.getMinutes())}:${this.adicionaZero(dateEnd.getSeconds())}Z`
		}

		let query = {}
		if (hasDate) {
			query = this.queryConfig.dateRangeQuery!.replace('{start_date}', formattedFromDate).replace('{end_date}', formattedToDate)
		} else {
			query = this.queryConfig.defaultQuery
		}

		this.notification.status = 2

		this.insightsService.executeQuery({ query: query }).subscribe({
			next: (result) => {
				this.notification.status = 3
				this.insightsData = this.insightsService.mapInsightsDataToObjects(result.tables)
				this.filteredInsights = this.insightsData
				this.collectionSize = this.insightsData.length
				this.paginateInsights()
				this.insightsCount = result.tables.length
				this.searchTermValue
			},
			error: (error) => {
				this.notificationService.showMessage(error.status, error, 'read', this.queryConfig.detailsRoute)
				console.error(error)
			},
		})
	}

  onDateSelection(event: any) {
		this.selectedDate = event
	}

	viewDetails(ids: any): void {
		this.insightsService.updateIds(ids)
		this.router.navigateByUrl(this.queryConfig.detailsRoute!)
	}

	verifyRequiredFields(input: HTMLInputElement): void {
		if (!this.selectedDate) {
			this.requiredMessage = 'Campo obrigatório!'
		} else {
			this.loadInsightsData()
		}
	}

	clearFilters(input: HTMLInputElement): void {
		if (this.selectedDate !== null) {
			input.value = ''
      this.searchTermValue = ''
			this.onDateSelection(null)
      this.loadInsightsData()
		} else {
			input.value = ''
			this.searchTermValue = ''
			this.requiredMessage = ''
			this.notification = { status: 0, message: '' }
		}
	}
}
