import { Component, OnInit, TemplateRef } from '@angular/core'
import { EChartsOption } from 'echarts'
import { InsightService } from '../insight.service'
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker'
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

interface Metrics {
	timestamp: string
	call_Count: number
  operations: []
}

@Component({
	selector: 'app-metric',
	templateUrl: './metric.component.html',
	styleUrls: ['./metric.component.scss'],
})
export class MetricComponent implements OnInit {
	colorTheme = 'theme-green'
	bsConfig: Partial<BsDatepickerConfig>

	metricsSuccess: Metrics[] = []
	metricsFailures: Metrics[] = []

	resultMetrics: any = []

	countMetricsSuccess: number[] = []
	countMetricsFailures: number[] = []

	timestampMetricsSuccess: string[] = []
	timestampMetricsFailures: string[] = []

	chartOptionFailures: EChartsOption = {}
	chartOptionSuccess: EChartsOption = {}

	//Modal
	modalRef: NgbModalRef
	closeResult: string = ''

	selectPeriod: any
	period: any = [
		{ time: '30 minutos', timeQuery: '30m' },
		{ time: '1 hora', timeQuery: '1h' },
		{ time: '4 hora', timeQuery: '4h' },
		{ time: '6 hora', timeQuery: '6h' },
		{ time: '12 hora', timeQuery: '12h' },
		{ time: '24 hora', timeQuery: '24h' },
		{ time: '3 dias', timeQuery: '3d' },
		{ time: '7 dias', timeQuery: '7d' },
		{ time: '30 dias', timeQuery: '30d' },
	]

	constructor(private insightsService: InsightService, private modalService: NgbModal) {}

	ngOnInit(): void {
		this.fetchMetrics('1d', true)
		this.fetchMetrics('1d', false)
	}

	applyTheme(pop: any) {
		// create new object on each property change
		// so Angular can catch object reference change
		this.bsConfig = Object.assign({}, { containerClass: this.colorTheme })
		setTimeout(() => {
			pop.show()
		})
	}

	fetchMetrics(time: string, resultCode: boolean): void {
		if (resultCode === true) {
			const query = {
				query: `requests
          | where timestamp >= ago(${time})
          | where success == true
          | summarize call_Count = count(), operations = make_list(operation_Name) by bin(timestamp, 1h)
          | project timestamp = format_datetime(bin(timestamp, 1h), 'yyyy-MM-dd HH:mm:ss'), call_Count, operations
          | order by timestamp asc
          `,
			}

			this.insightsService.executeQuery(query).subscribe({
				next: (result) => {
					this.metricsSuccess = this.insightsService.mapInsightsDataToObjects(result.tables)
					this.updateChart(true)
				},
				error: (error) => {
					console.error('Error fetching data', error)
				},
			})
		} else {
			const query = {
				query: `requests
          | where timestamp >= ago(${time})
          | where success == false
          | summarize call_Count = count(), operations = make_list(operation_Name) by bin(timestamp, 1h)
          | project timestamp = format_datetime(bin(timestamp, 1h), 'yyyy-MM-dd HH:mm:ss'), call_Count, operations
          | order by timestamp asc`,
			}

			this.insightsService.executeQuery(query).subscribe({
				next: (result) => {
					this.metricsFailures = this.insightsService.mapInsightsDataToObjects(result.tables)
					this.updateChart(false)
				},
				error: (error) => {
					console.error('Error fetching data', error)
				},
			})
		}
	}


	updateChart(status: boolean): void {
    const rootStyles = getComputedStyle(document.documentElement);
    const roxoColor = rootStyles.getPropertyValue('--roxo').trim();
    const roxoClaroColor = rootStyles.getPropertyValue('--roxo-transp').trim();

		if (status === true) {
      this.countMetricsSuccess = this.metricsSuccess.map((item) => item.call_Count)
      this.timestampMetricsSuccess = this.metricsSuccess.map((item) => item.timestamp.split(' ')[1])
      this.chartOptionSuccess = {
        tooltip: {
          trigger: 'axis',
          formatter: (params: any) => {
            const data = params[0].data; // O valor do ponto
            const item = this.metricsSuccess.find((item) => item.call_Count === params[0].data)
            const name = item?.timestamp // O nome do ponto no eixo X
            return `Dia: ${name} <br/> Valor: ${data}`; // Texto exibido no tooltip
          }
        },
        xAxis: {
          type: 'category',
          data: this.timestampMetricsSuccess,
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.countMetricsSuccess,
						type: 'line',
            lineStyle: {
              color: '#5470C6',  // Cor da linha
              width: 2           // Espessura da linha
            },
            itemStyle: {
              color: '#03a3ff',  // Cor dos pontos
            }
          }
        ]
      };
		} else {
      this.countMetricsFailures = this.metricsFailures.map((item) => item.call_Count)
		  this.timestampMetricsFailures = this.metricsFailures.map((item) => item.timestamp.split(' ')[1])

      this.chartOptionFailures = {
        tooltip: {
          trigger: 'axis',
          formatter: (params: any) => {
            const data = params[0].data;
            const item = this.metricsFailures.find((item) => item.call_Count === params[0].data)
            const name = item?.timestamp 
            return `Dia: ${name} <br/> Valor: ${data}`; 
          }
        },
        xAxis: {
          type: 'category',
          data: this.timestampMetricsFailures,
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.countMetricsFailures,
						type: 'line',
            lineStyle: {
              color: roxoClaroColor, 
              width: 2           
            },
            itemStyle: {
              color: roxoColor,  
            }
          }
        ]
      };
		}
	}

	onChartClick(event: any, status: any, form: TemplateRef<any>): void {
    if(status === 'Success') {
      this.resultMetrics = this.metricsSuccess.find((item) => item.call_Count === event.data)
    } else {
      this.resultMetrics = this.metricsFailures.find((item) => item.call_Count === event.data)
    }
    this.openModal(form)
	}

	onChangePeriod(event: any) {
		this.fetchMetrics(event, true)
		this.fetchMetrics(event, false)
	}

	openModal(form: TemplateRef<any>) {
		this.modalRef = this.modalService.open(form, { size: 'xl', scrollable: true, centered: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	onDismiss() {
		this.modalRef.dismiss()
		this.resultMetrics = []
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}
}
